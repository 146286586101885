import { Routes, Route } from 'react-router-dom';
import { auth } from './firebase';
import AddEditorial from './pages/addEditorial/addEditorial';
import AddManga from './pages/addManga/addManga';
import AddSerie from './pages/addSerie/addSerie';
import CartPage from './pages/cartPage/cartPage';
import Dashboard from './pages/dashboard/dashboard';
import Home from './pages/home/home';
import Login from './pages/login/login';
import Manga from './pages/manga/manga';
import Mangas from './pages/mangas/mangas';
import News from './pages/novedades/news';
import PaymentPage from './pages/paymentPage/paymentPage';
import Register from './pages/register/register';
import Search from './pages/searchByTitle/searchByTitle';
import AddSerieComic from './pages/addSerieComic/addSerie';
import AddCollection from './pages/addCollection/addCollection';
import PostComicCol from './pages/AddComicCol/addComicCol';
import AddComic from './pages/addComic/addComic';
import EditorialPage from './pages/editorialPage/editorialPage';
import AdminVentas from './pages/adminVentas/adminVentas';
import Comics from './pages/comics/comics';
import Contacto from './pages/contacto/contacto';
import Author from './pages/authorPage/author';
import Comic from './pages/comic/comic';
import Serie from './pages/seriePage/serie';
import AddSerieLibro from './pages/addSerieLibro/addSerieLibro';
import AddLibro from './pages/addLibro/addLibro';
import Libro from './pages/libro/libro';
import Libros from './pages/libros/libros';
import UserPage from './pages/userPage/userPage';
import ReportBug from './pages/reportBug/reportBug';
import Building from './components/pageBuilding/pageBuilding';

import React, { useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import Stock from './pages/stock/stock';
import Catalogo from './pages/catalogo/catalogo';
import AddFigura from './pages/addFigura/addFigura';
import Figura from './pages/figura/figura';
import PageFiguras from './pages/pageFiguras/pageFiguras';
import Figuras from './pages/home/figuras/figuras';

function getCart() {
  if (auth.currentUser) {
    return true;
  } else {
    return false;
  }
}

function App() {

  const history = useLocation()

  useEffect(() => {
    return () => {
      //alert("holi")
      window.location.reload(false)
    }
  }, [history])

  return (
    <Routes>
      {
        /*
      <Route path="*" element={<Building />} />
      */
      }
      <Route path="/catalogo" element={<Catalogo />} />
      <Route exact path="/" element={<Home />} />
      <Route path="/novedades" element={<News />} />
      <Route path='/user/register' element={<Register />} />
      <Route path='/search/editorial' element={<EditorialPage />} />
      <Route path='/serie' element={<Serie />} />
      <Route path='/autores' element={<Author />} />
      <Route path="/comic/:id" element={<Comic />} />
      <Route path="/libro/:id" element={<Libro />} />
      <Route path="/search/mangas" element={<Mangas />} />
      <Route path="/search/comics" element={<Comics />} />
      <Route path="/search/libros" element={<Libros />} />
      <Route path='/micarrito' element={<CartPage />} />
      <Route path="/manga/:id" element={<Manga />} />
      <Route path="/search/bytitle" element={<Search />} key={Date.now()} />
      <Route path='/user' element={<UserPage />} />
      <Route path="/itstimetopay" element={<PaymentPage cart={getCart()} />} />
      <Route path='/figuras/:id' element={<Figura />} />
      <Route path='/search/figuras/:tag' element={<PageFiguras />} />
      <Route path='/search/figuras' element={<Figuras />} />
      <Route path='/adminventas' element={<AdminVentas />} />
      <Route path='/stock' element={<Stock />} />
      <Route path="/postmanga" element={<AddManga />} />
      <Route path="/postlibro" element={<AddLibro />} />
      <Route path="/posteditorial" element={<AddEditorial />} />
      <Route path="/postserie" element={<AddSerie />} />
      <Route path="/postserielibro" element={<AddSerieLibro />} />
      <Route path='/user/login' element={<Login />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/postseriecomic' element={<AddSerieComic />} />
      <Route path='/postcoleccion' element={<AddCollection />} />
      <Route path='/postcomic-coleccion' element={<PostComicCol />} />
      <Route path='/postcomic-serie' element={<AddComic />} />
      <Route path='/postfigura' element={<AddFigura />} />
      <Route path='/reportbugs' element={<ReportBug />} />
      <Route path='/nos/contacto' element={<Contacto />} />
    </Routes>
  );
}

export default App;