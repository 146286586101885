import React from 'react'

import './discounts.css'

import Swal from 'sweetalert2';

import { connect } from "react-redux";
import { setDiscount, deleteDiscount, createDiscountCode } from "../../redux/actions";

class Discounts extends React.Component {
    constructor(props) {
        super(props);
    }

    setDiscount = (e) => {
        e.preventDefault()
        e.target.disabled = true;
        const data = {
            editorial: document.getElementById('select-ed-discount').value,
            descuento: document.getElementById('descuento').value
        }

        this.props.setDiscount(data)
    }

    deleteDiscount = (e) => {
        e.preventDefault()
        e.target.disabled = true;
        const data = {
            editorial: document.getElementById('select-ed-discount').value
        }

        this.props.deleteDiscount(data)
    }

    setDiscountCode = (e) => {
        e.preventDefault()
        e.target.disabled = true;
        const data = {
            code: document.getElementById('discount-code').value,
            discount: Number(document.getElementById('discount-number').value)
        }

        this.props.createDiscountCode(data)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.state.d_status !== this.props.state.d_status) {
            console.log(this.props.state.d_status)
            if (this.props.state.d_status === "success" || !this.props.state.d_status.error) {
                Swal.fire({
                    icon: 'success',
                    title: "Descuento actualizado con éxito!"
                })
                    .then(() => {
                        window.location.reload()
                    })
            }
        }
    }

    render() {
        return (
            <div className='discounts-main-container'>
                <section className='discounts-main-container'>
                    <select className='update-select' id="select-ed-discount">
                        {this.props.editoriales.map((e) => {
                            return (
                                <option key={e.id}>
                                    {e.nombre}
                                </option>
                            )
                        })}
                    </select>
                    <input type="number" className='change-input' id="descuento" />
                    <button className='update-button' id="agregar-descuetno" onClick={this.setDiscount}>Agregar descuento</button>
                    <button className='update-button' id="delete-discount" onClick={this.deleteDiscount}>Borrar descuento</button>
                </section>
                <br/>
                <section className='discounts-main-container'>
                    <input className='update-select' type='text' id='discount-code' placeholder='Código' />
                    <input className='update-select' type='number' id='discount-number' placeholder='Descuento (%)' />
                    <button className='update-button' id="agregar-code" onClick={this.setDiscountCode}>Agregar código de descuento</button>
                </section>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    setDiscount,
    deleteDiscount,
    createDiscountCode
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(Discounts);