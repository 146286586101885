import { GET_FIGURA, STATUS_PRICE_F, GET_ALL, PAYMENT, GET_EDITORIALES, POST_EDITORIAL, POST_MANGA, POST_SERIE, GET_MANGAS, SEARCH_TITLE, GET_SERIES, GET_MANGA, GET_NEWS, REGISTER, GET_USER, POST_CART, GET_CART, DELETE_ITEM, POST_SERIE_COMIC, POST_COMIC, GET_SERIES_COMIC, POST_COLECCION, GET_COLECCIONES, GET_COMICS, ADD_TO_FAVS, GET_FAVS, GET_PROVS, GET_LOC, CHANGE_PRICE, SIM_PURCHASE, CONSULTA, GET_COMIC, SUBSCRIBE, STOCK, UPDATE_SERIE, POST_SERIE_LIBRO, POST_LIBRO, GET_SERIES_LIBRO, GET_LIBRO, GET_LIBROS, UPDATE_VENTAS, GET_COMPRAS, GET_VENTAS, GET_VOL, REPORT, AUTHORS, SAME_SERIE, GET_STOCK, GET_LESS, SET_DISCOUNT, DELETE_DISCOUNT, GET_SPECIAL, GET_CATALOGO, GET_FIGURAS, STATUS_STOCK_F, DISCOUNT_CODE } from '../actions';

const initialState = {
    editoriales: [],
    series: [],
    mangas: [],
    post_state: "",
    manga: {},
    status: {},
    user: {},
    cart: [],
    guest_cart: [],
    colecciones: [],
    news: [],
    vols: [],
    comics: [],
    favs: [],
    provincias: [],
    loc: [],
    comic: {},
    subscribe: {},
    update_status: {},
    series_libro: [],
    libro: {},
    libros: [],
    venta_status: {},
    compras: [],
    ventas: {},
    vol: {},
    report_state: {},
    authors: [],
    allSeries: {},
    stock: [],
    products: [],
    d_status: {},
    special: [],
    catalogo: {},
    figura: {},
    figuras: [],
    status_price_f: null,
    status_stock_f: null,
    discount_code: null
}

export default function reducer(state = initialState, actions) {
    switch (actions.type) {
        case DISCOUNT_CODE:
            return {
                ...state,
                discount_code: actions.payload
            }
        case GET_FIGURAS:
            return {
                ...state,
                figuras: actions.payload
            }
        case GET_FIGURA:
            return {
                ...state,
                figura: actions.payload
            }
        case GET_EDITORIALES:
            return {
                ...state,
                editoriales: actions.payload
            }
        case POST_SERIE:
            return {
                ...state,
                post_state: actions.payload
            }
        case POST_SERIE_COMIC:
            return {
                ...state,
                post_state: actions.payload
            }
        case POST_MANGA:
            return {
                ...state,
                post_state: actions.payload
            }
        case POST_COMIC:
            return {
                ...state,
                post_state: actions.payload
            }
        case POST_EDITORIAL:
            return {
                ...state,
                post_state: actions.payload
            }
        case POST_COLECCION:
            return {
                ...state,
                post_state: actions.payload
            }
        case GET_MANGAS:
            return {
                ...state,
                mangas: actions.payload
            }
        case SEARCH_TITLE:
            return {
                ...state,
                vols: actions.payload
            }
        case GET_SERIES:
            return {
                ...state,
                series: actions.payload
            }
        case GET_SERIES_COMIC:
            return {
                ...state,
                series: actions.payload
            }
        case GET_COLECCIONES:
            return {
                ...state,
                colecciones: actions.payload
            }
        case GET_MANGA:
            return {
                ...state,
                manga: actions.payload
            }
        case PAYMENT:
            return {
                ...state,
                status: actions.payload
            }
        case REGISTER:
            return {
                ...state,
                status: actions.payload
            }
        case GET_USER:
            return {
                ...state,
                user: actions.payload
            }
        case POST_CART:
            return {
                ...state,
                status: actions.payload
            }
        case GET_CART:
            return {
                ...state,
                cart: actions.payload
            }
        case DELETE_ITEM:
            return {
                ...state,
                status: actions.payload
            }
        case GET_NEWS:
            return {
                ...state,
                news: actions.payload
            }
        case GET_COMICS:
            return {
                ...state,
                comics: actions.payload
            }
        case GET_FAVS:
            return {
                ...state,
                favs: actions.payload
            }
        case ADD_TO_FAVS:
            return {
                ...state,
                post_state: actions.payload
            }
        case GET_PROVS:
            return {
                ...state,
                provincias: actions.payload
            }
        case GET_LOC:
            return {
                ...state,
                loc: actions.payload
            }
        case CHANGE_PRICE:
            return {
                ...state,
                status: actions.payload
            }
        case SIM_PURCHASE:
            return {
                ...state,
                status: actions.payload
            }
        case CONSULTA:
            return {
                ...state,
                status: actions.payload
            }
        case GET_COMIC:
            return {
                ...state,
                comic: actions.payload
            }
        case SUBSCRIBE:
            return {
                ...state,
                subscribe: actions.payload
            }
        case STOCK:
            return {
                ...state,
                status: actions.payload
            }
        case UPDATE_SERIE:
            return {
                ...state,
                update_status: actions.payload
            }
        case POST_SERIE_LIBRO:
            return {
                ...state,
                post_state: actions.payload
            }
        case POST_LIBRO:
            return {
                ...state,
                post_state: actions.payload
            }
        case GET_SERIES_LIBRO:
            return {
                ...state,
                series_libro: actions.payload
            }
        case GET_LIBRO:
            return {
                ...state,
                libro: actions.payload
            }
        case GET_LIBROS:
            return {
                ...state,
                libros: actions.payload
            }
        case UPDATE_VENTAS:
            return {
                ...state,
                venta_status: actions.payload
            }
        case GET_VENTAS:
            return {
                ...state,
                ventas: actions.payload
            }
        case GET_COMPRAS:
            return {
                ...state,
                compras: actions.payload
            }
        case GET_VOL:
            return {
                ...state,
                vol: actions.payload
            }
        case REPORT:
            return {
                ...state,
                report_state: actions.payload
            }
        case AUTHORS:
            return {
                ...state,
                authors: actions.payload
            }
        case SAME_SERIE:
            return {
                ...state,
                vols: actions.payload
            }
        case GET_ALL:
            return {
                ...state,
                allSeries: actions.payload
            }
        case GET_STOCK:
            return {
                ...state,
                stock: actions.payload
            }
        case GET_LESS:
            return {
                ...state,
                products: actions.payload
            }
        case SET_DISCOUNT:
            return {
                ...state,
                d_status: actions.payload
            }
        case DELETE_DISCOUNT:
            return {
                ...state,
                d_status: actions.payload
            }
        case GET_SPECIAL:
            return {
                ...state,
                special: actions.payload
            }
        case GET_CATALOGO:
            return {
                ...state,
                catalogo: actions.payload
            }
        case STATUS_STOCK_F:
            return {
                ...state,
                status_stock_f: actions.payload
            }
        case STATUS_PRICE_F:
            return {
                ...state,
                status_price_f: actions.payload
            }
        default:
            return {
                ...state
            }
    }
}