import React from 'react';
import logo from '../../imgs/logo.jpg'
import Swal from 'sweetalert2'

import { connect } from 'react-redux';
import { sendEmail } from "../../redux/actions";

import { GrLocation } from 'react-icons/gr'
import { BsInstagram, BsFacebook } from 'react-icons/bs'

import './contacto.css'

class Contacto extends React.Component{

    componentDidMount(){
        setTimeout(()=>{
            let buttons = document.getElementsByClassName("nav-button");
            for(let i = 0; i < buttons.length; i++){
                if(buttons[i].id === "contacto-nav-button"){
                    buttons[i].disabled = true;
                }else{
                    buttons[i].disabled = false;
                }
            }
        }, 250);
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const email =   document.getElementById("input-email").value;
        const name  =   document.getElementById("input-nombre-c").value;
        const msg   =   document.getElementById("input-mensaje").value;

        if(email.length === 0){
            return Swal.fire({
                title: "Por favor, ingrese su email!",
                icon: "error"
            })
        }

        const data = {
            email: email,
            message: msg,
            user: name
        }

        this.props.sendEmail(data);

        return Swal.fire({
            title: "Envíando consulta...",
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false
        })

    }

    componentDidUpdate(prevProps){
        if(prevProps.state.status !== this.props.state.status){
            if(this.props.state.status === "success"){
                Swal.fire({
                    icon: "success",
                    title: "Su consulta fue envíada con éxito!"
                })
                .then(()=>{
                    window.location.reload();
                })
            }
        }
    }

    render(){
        return(
            <div className='contacto-main-container'>
                <div className='info-container'>
                    <img src={logo} className="contact-logo" />
                    <span className='info'>
                        <span className='info-s'>
                            <BsInstagram className='info-icon' />
                            <a className='info-p'
                            href='https://www.instagram.com/thebookhall_lyc/'
                            target="_blank" rel="noreferrer">
                                thebookhall_lyc
                            </a>
                        </span>
                        <span className='info-s'>
                            <BsFacebook className='info-icon' />
                            <a className='info-p'
                            href='https://www.facebook.com/ThebookhallLyC'
                            target="_blank" rel="noreferrer">
                                The Book Hall Libros & Cómics
                            </a>
                        </span>
                        <span className='info-s'>
                            <GrLocation className='info-icon' />
                            <a className='info-p'
                            href='https://maps.app.goo.gl/xhokiQitxhtpke7J8'
                            target="_blank" rel="noreferrer">
                                Gral. Las Heras 261 - Local 9, Ituzaingó
                            </a>
                        </span>
                    </span>
                </div>
                <form className='contact-form' onSubmit={this.handleSubmit}>
                    <h1 className='form-title'>CONSULTAS</h1>
                    <input className='contact-input'
                        type="email"
                        id='input-email'
                        placeholder='Email'
                    />
                    <input className='contact-input'
                        type="text"
                        id='input-nombre-c'
                        placeholder='Nombre'
                    />
                    <textarea className='contact-input'
                        id='input-mensaje'
                        placeholder='Mensaje...'
                    />
                    <button className='contact-button' type='submit'>Enviar</button>
                </form>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    sendEmail
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(Contacto);