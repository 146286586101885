import React from 'react';

import './footer.css';

class Footer extends React.Component{
    
    render(){
        return(
            <div className='footer-main-container'>
                <h1 className='footer-title'>THE BOOK HALL</h1>
                <span className='bookhall-info'>
                    <p className='footer-info'>
                        The Book Hall Libros & Cómics
                    </p>
                    <p className='footer-info'>
                        Horario: Lun - Sab, 11 a 19hs
                    </p>
                    <p className='footer-info'>
                        Gral. Las Heras 261 - Local 9, Ituzaingó
                    </p>
                    <a className='footer-info' href='https://www.instagram.com/thebookhall_lyc/?hl=es'>
                        Ig.: @thebookhall_lyc 
                    </a>
                    <p className='footer-info' id="copyright">
                        Web desarrollada por Bautista Manolizi, 2022
                    </p>
                </span>
            </div>
        )
    }

}

export default Footer;