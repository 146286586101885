import axios from 'axios';

import tokens from '../../variables';
import localidades from '../../loc.js'

export const POST_MANGA = "POST_MANGA";
export const POST_EDITORIAL = "POST_EDITORIAL";
export const POST_SERIE = "POST_SERIE";
export const POST_SERIE_COMIC = "POST_SERIE_COMIC";
export const GET_EDITORIALES = "GET_EDITORIALES";
export const GET_MANGAS = "GET_MANGAS";
export const LOG_IN = "LOG_IN";
export const SEARCH_TITLE = "SEARCH_TITLE";
export const GET_SERIES = "GET_SERIES";
export const GET_MANGA = "GET_MANGA";
export const PAYMENT = "PAYMENT";
export const GET_NEWS = "GET_NEWS";
export const REGISTER = "REGISTER";
export const GET_USER = "GET_USER";
export const POST_CART = "POST_CART";
export const GET_CART = "GET_CART";
export const DELETE_ITEM = "DELETE_ITEM";
export const POST_COMIC = "POST_COMIC";
export const GET_SERIES_COMIC = "GET_SERIES_COMIC";
export const POST_COLECCION = "POST_COLECCION";
export const GET_COLECCIONES = "GET_COLECCIONES";
export const GET_COMICS = "GET_COMICS";
export const ADD_TO_FAVS = "ADD_TO_FAVS";
export const GET_FAVS = "GET_FAVS";
export const GET_PROVS = "GET_PROVS";
export const GET_LOC = "GET_LOC";
export const CHANGE_PRICE = "CHANGE_PRICE";
export const SIM_PURCHASE = "SIM_PURCHASE";
export const CONSULTA = "CONSULTA";
export const GET_COMIC = "GET_COMIC";
export const SUBSCRIBE = "SUSCRIBE";
export const STOCK = "STOCK";
export const UPDATE_SERIE = "UPDATE_SERIE";
export const POST_SERIE_LIBRO = "POST_SERIE_LIBRO";
export const POST_LIBRO = "POST_LIBRO";
export const GET_SERIES_LIBRO = "GET_SERIES_LIBRO";
export const GET_LIBRO = "GET_LIBRO";
export const GET_LIBROS = "GET_LIBROS";
export const UPDATE_VENTAS = "UPDATE_VENTAS";
export const GET_COMPRAS = "GET_COMRPAS";
export const GET_VENTAS = "GET_VENTAS";
export const GET_VOL = "GET_VOL";
export const REPORT = "REPORT";
export const AUTHORS = "AUTHORS";
export const SAME_SERIE = "SAME_SERIE";
export const GET_ALL = "GET_ALL";
export const GET_STOCK = "GET STOCK";
export const GET_LESS = "GET_LESS";
export const SET_DISCOUNT = "SET_DISCOUNT"
export const DELETE_DISCOUNT = "DELETE_DISCOUNT"
export const GET_SPECIAL = "GET_SPECIAL";
export const GET_CATALOGO = "GET_CATALOGO";
export const GET_FIGURA = "GET_FIGURA";
export const GET_FIGURAS = 'GET_FIGURAS'
export const STATUS_PRICE_F = "STATUS_PRICE_F"
export const STATUS_STOCK_F = "STATUS_STOCK_F"
export const DISCOUNT_CODE = "DISCOUNT_CODE"

export function figsByTag(tag){
    return function(dispatch){
        axios.get(`${tokens.server}getbytag/${tag}`)
        .then((response)=>{
            dispatch({
                type: GET_FIGURAS,
                payload: response.data
            })
        })
    }
}

export function postFigura(figura_data){
    return function(dispatch){
        axios.post(`${tokens.server}postfigura`, figura_data)
        .then((response)=>{
            dispatch({
                type: POST_MANGA,
                payload: response.data
            })
        })
    }
}


export function getSeriesAll(){
    return function(dispatch){
        axios.get(`${tokens.server}getseriesall`)
        .then((response)=>{
            dispatch({
                type: GET_ALL,
                payload: response.data
            })
        })
    }
}

export function getLess(){
    return function(dispatch){
        axios.get(`${tokens.server}getless`)
        .then((response)=>{
            dispatch({
                type: GET_LESS,
                payload: response.data
            })
        })
    }
}

export function postEditorial(editorial_info){
    return function(dispatch){
        axios.post(`${tokens.server}posteditorial`, editorial_info)
        .then((response)=>{
            dispatch({
                type: POST_EDITORIAL,
                payload: response.data
            })
        })
    }
}

export function postSerie(serie_info){
    return function(dispatch){
        axios.post(`${tokens.server}postserie`, serie_info)
        .then((response)=>{
            dispatch({
                type: POST_SERIE,
                payload: response.data
            })
        })
    }
}

export function postSerieLibro(serie_info){
    return function(dispatch){
        axios.post(`${tokens.server}postserielibro`, serie_info)
        .then((response)=>{
            dispatch({
                type: POST_SERIE_LIBRO,
                payload: response.data
            })
        })
    }
}

export function postSerieComic(serie_info){
    return function(dispatch){
        axios.post(`${tokens.server}postseriecomic`, serie_info)
        .then((response)=>{
            dispatch({
                type: POST_SERIE_COMIC,
                payload: response.data
            })
        })
    }
}

export function postCollection(serie_info){
    return function(dispatch){
        axios.post(`${tokens.server}postcolection`, serie_info)
        .then((response)=>{
            dispatch({
                type: POST_COLECCION,
                payload: response.data
            })
        })
    }
}

export function postManga(editorial_info){
    return function(dispatch){
        axios.post(`${tokens.server}postmanga`, editorial_info)
        .then((response)=>{
            dispatch({
                type: POST_MANGA,
                payload: response.data
            })
        })
    }
}

export function postLibro(data){
    return function(dispatch){
        axios.post(`${tokens.server}postlibro`, data)
        .then((response)=>{
            dispatch({
                type: POST_LIBRO,
                payload: response.data
            })
        })
    }
}

export function postComic(editorial_info){
    return function(dispatch){
        axios.post(`${tokens.server}postcomic`, editorial_info)
        .then((response)=>{
            dispatch({
                type: POST_COMIC,
                payload: response.data
            })
        })
    }
}

export function getEditoriales(){
    return function(dispatch){
        axios.get(`${tokens.server}geteditoriales`)
        .then((response)=>{
            dispatch({
                type: GET_EDITORIALES,
                payload: response.data
            })
        })
    }
}

export function getMangas(){
    return function(dispatch){
        axios.get(`${tokens.server}getmangas`)
        .then((response)=>{
            dispatch({
                type: GET_MANGAS,
                payload: response.data
            })
        })
    }
}

export function getFiguras(){
    return function(dispatch){
        axios.get(`${tokens.server}getfiguras`)
        .then((response)=>{
            dispatch({
                type: GET_FIGURAS,
                payload: response.data
            })
        })
    }
}

export function getLibros(){
    return function(dispatch){
        axios.get(`${tokens.server}getlibros`)
        .then((response)=>{
            dispatch({
                type: GET_LIBROS,
                payload: response.data
            })
        })
    }
}

export function searchByTitle(url){
    return function(dispatch){
        axios.get(`${tokens.server}search/title?name=${url}`)
        .then((response)=>{
            dispatch({
                type: SEARCH_TITLE,
                payload: response.data
            })
        })
    }
}

export function getSeries(){
    return function(dispatch){
        axios.get(`${tokens.server}getseries`)
        .then((response)=>{
            dispatch({
                type: GET_SERIES,
                payload: response.data
            })
        })
    }
}

export function getSeriesLibro(){
    return function(dispatch){
        axios.get(`${tokens.server}getserieslibro`)
        .then((response)=>{
            dispatch({
                type: GET_SERIES_LIBRO,
                payload: response.data
            })
        })
    }
}

export function getSeriesComic(){
    return function(dispatch){
        axios.get(`${tokens.server}getseriescomic`)
        .then((response)=>{
            dispatch({
                type: GET_SERIES_COMIC,
                payload: response.data
            })
        })
    }
}

export function getColecciones(){
    return function(dispatch){
        axios.get(`${tokens.server}getcolecciones`)
        .then((response)=>{
            dispatch({
                type: GET_COLECCIONES,
                payload: response.data
            })
        })
    }
}

export function getManga(data){
    return function(dispatch){
        axios.get(`${tokens.server}getmanga/${data.id}/${data.n}/${data.special}`)
        .then((response)=>{
            dispatch({
                type: GET_MANGA,
                payload: response.data
            })
        })
    }
}

export function getFigura(id){
    return function(dispatch){
        axios.get(`${tokens.server}getfigura/${id}`)
        .then((response)=>{
            dispatch({
                type: GET_FIGURA,
                payload: response.data
            })
        })
    }
}

export function getLibro(data){
    return function(dispatch){
        axios.get(`${tokens.server}getlibro/${data.id}/${data.n}`)
        .then((response)=>{
            dispatch({
                type: GET_LIBRO,
                payload: response.data
            })
        })
    }
}

export function getComic(data){
    return function(dispatch){
        axios.get(`${tokens.server}getcomic/${data.id}/${data.name}`)
        .then((response)=>{
            dispatch({
                type: GET_COMIC,
                payload: response.data
            })
        })
    }
}

export function qrPaymentMODO(data){
    return function(dispatch){
        axios.post(`${tokens.server}qr_payment_galicia`, data)
        .then((response)=>{
            dispatch({
                type: PAYMENT,
                payload: response.data
            })
        })
    }
}

export function qrPaymentDNI(data){
    return function(dispatch){
        axios.post(`${tokens.server}qr_payment_dni`, data)
        .then((response)=>{
            dispatch({
                type: PAYMENT,
                payload: response.data
            })
        })
    }
}

export function payment(data){
    return function(dispatch){
        axios.post(`${tokens.server}process_payment`, data)
        .then((response)=>{
            dispatch({
                type: PAYMENT,
                payload: response.data
            })
        })
    }
}

export function register(user){
    return function(dispatch){
        axios.post(`${tokens.server}register`, user)
        .then((response)=>{
            dispatch({
                type: REGISTER,
                payload: response.data
            })
        })
    }
}

export function getUser(id){
    return function(dispatch){
        axios.get(`${tokens.server}getuser/${id}`)
        .then((response)=>{
            dispatch({
                type: GET_USER,
                payload: response.data
            })
        })
    }
}

export function addToCart(data){
    return function(dispatch){
        axios.post(`${tokens.server}posttocart`, data)
        .then((response)=>{
            dispatch({
                type: POST_CART,
                payload: response.data
            })
        })
    }
}

export function getCartItems(id){
    return function(dispatch){
        axios.get(`${tokens.server}getcartitems/${id}`)
        .then((response)=>{
            dispatch({
                type: GET_CART,
                payload: response.data
            })
        })
    }
}

export function guestCartItems(ids){
    return function(dispatch){
        axios.post(`${tokens.server}guestcartitems`, ids)
        .then((response)=>{
            dispatch({
                type: GET_CART,
                payload: response.data
            })
        })
    }
}

export function deleteItem(data){
    return function(dispatch){
        axios.post(`${tokens.server}deleteitem`, data)
        .then((response)=>{
            dispatch({
                type: DELETE_ITEM,
                payload: response.data
            })
        })
    }
}

export function getNews(){
    return function(dispatch){
        axios.get(`${tokens.server}getnews`)
        .then((response)=>{
            dispatch({
                type: GET_NEWS,
                payload: response.data
            })
        })
    }
}

export function getComics(){
    return function(dispatch){
        axios.get(`${tokens.server}getcomics`)
        .then((response)=>{
            dispatch({
                type: GET_COMICS,
                payload: response.data
            })
        })
    }
}

export function addtofavs(data){
    return function(dispatch){
        axios.post(`${tokens.server}addtofavs`, data)
        .then((response)=>{
            dispatch({
                type: ADD_TO_FAVS,
                payload: response.data
            })
        })
    }
}

export function getFavs(id){
    return function(dispatch){
        axios.post(`${tokens.server}getfavs?id=${id}`)
        .then((response)=>{
            dispatch({
                type: GET_FAVS,
                payload: response.data
            })
        })
    }
}

export function getProvincias(){
    return function(dispatch){
        axios.get(`https://apis.datos.gob.ar/georef/api/provincias`)
        .then((response)=>{
            dispatch({
                type: GET_PROVS,
                payload: response.data.provincias
            })
        })
    }
}

export function getLoc(prov, nombre){
    return function(dispatch){
        const loc = []
        localidades.map((l)=>{
            if(l.nombre.toLowerCase().includes(nombre.toLowerCase()) && l.provincia_nombre === prov){
                loc.push(l)
            }
        })
        return dispatch({
            type: GET_LOC,
            payload: loc
        })
    }
}

export function changePrice(data){
    return function(dispatch){
        axios.post(`${tokens.server}changeprice`, data)
        .then((response)=>{
            dispatch({
                type: CHANGE_PRICE,
                payload: response.data
            })
        })
        .catch((err)=>{
            console.log(err)
        })
    }
}

export function changePriceFigura(data){
    return function(dispatch){
        axios.put(`${tokens.server}change_price_figuras`, data)
        .then((response)=>{
            dispatch({
                type: STATUS_PRICE_F,
                payload: response.data
            })
        })
    }
}

export function changeStockFigura(data){
    return function(dispatch){
        axios.put(`${tokens.server}change_stock_figuras`, data)
        .then((response)=>{
            dispatch({
                type: STATUS_STOCK_F,
                payload: response.data
            })
        })
    }
}

export function simPurchase(data){
    return function(dispatch){
        axios.post(`${tokens.server}simpurchase`, data)
        .then((response)=>{
            dispatch({
                type: SIM_PURCHASE,
                payload: response.data
            })
        })
    }
}

export function sendEmail(data){
    return function(dispatch){
        axios.post(`${tokens.server}sendemail`, data)
        .then((response)=>{
            dispatch({
                type: CONSULTA,
                payload: response.data
            })
        })
    }
}

export function subscribe(data){
    return function(dispatch){
        axios.post(`${tokens.server}subscribe`, data)
        .then((response)=>{
            dispatch({
                type: SUBSCRIBE,
                payload: response.data
            })
        })
    }
}

export function updateStock(data){
    console.log(data)
    return function(dispatch){
        axios.post(`${tokens.server}updatestock`, data)
        .then((response)=>{
            dispatch({
                type: STOCK,
                payload: response.data
            })
        })
        .catch((err)=>{
            console.log(err)
        })
    }
}

export function updateSerie(data){
    return function(dispatch){
        axios.post(`${tokens.server}updateserie`, data)
        .then((response)=>{
            dispatch({
                type: UPDATE_SERIE,
                payload: response.data
            })
        })
    }
}

export function updateVentas(data){
    return function(dispatch){
        axios.post(`${tokens.server}updateventas`, data)
        .then((response)=>{
            dispatch({
                type: UPDATE_VENTAS,
                payload: response.data
            })
        })
    }
}

export function getCompras(id){
    return function(dispatch){
        axios.get(`${tokens.server}getcompras/${id}`)
        .then((response)=>{
            dispatch({
                type: GET_COMPRAS,
                payload: response.data
            })
        })
    }
}

export function getVentas(){
    return function(dispatch){
        axios.get(`${tokens.server}getventas`)
        .then((response)=>{
            dispatch({
                type: GET_VENTAS,
                payload: response.data
            })
        })
    }
}

export function getVol(id){
    return function(dispatch){
        axios.get(`${tokens.server}getvol/${id}`)
        .then((response)=>{
            dispatch({
                type: GET_VOL,
                payload: response.data
            })
        })
    }
}

export function reportBug(data){
    return function(dispatch){
        axios.post(`${tokens.server}reportbug`, data)
        .then((response)=>{
            dispatch({
                type: REPORT,
                payload: response.data
            })
        })
    }
}

export function getAuthors(authors){
    return function(dispatch){
        axios.post(`${tokens.server}getauthors`, authors)
        .then((response)=>{
            dispatch({
                type: AUTHORS,
                payload: response.data
            })
        })
    }
}

export function sameSerie(serie){
    return function(dispatch){
        axios.post(`${tokens.server}sameserie`, serie)
        .then((response)=>{
            dispatch({
                type: SAME_SERIE,
                payload: response.data
            })
        })
    }
}

export function getStock(){
    return function(dispatch){
        axios.get(`${tokens.server}getstock`)
        .then((response)=>{
            dispatch({
                type: GET_STOCK,
                payload: response.data
            })
        })
    }
}

export function setDiscount(data){
    return function(dispatch){
        axios.post(`${tokens.server}setdiscount`, data)
        .then((response)=>{
            dispatch({
                type: SET_DISCOUNT,
                payload: response.data
            })
        })
    }
}

export function createDiscountCode(data){
    return function(dispatch){
        axios.post(`${tokens.server}discount/code`, data)
        .then((response)=>{
            dispatch({
                type: SET_DISCOUNT,
                payload: response.data
            })
        })
    }
}

export function deleteDiscount(data){
    return function(dispatch){
        axios.post(`${tokens.server}deletediscount`, data)
        .then((response)=>{
            dispatch({
                type: DELETE_DISCOUNT,
                payload: response.data
            })
        })
    }
}

export function getSpecial(){
    return function(dispatch){
        axios.get(`${tokens.server}getspecial`)
        .then((response)=>{
            dispatch({
                type: GET_SPECIAL,
                payload: response.data
            })
        })
    }
}

export function getCatalogo(){
    return function(dispatch){
        axios.get(`${tokens.server}getcatalogo`)
        .then((response)=>{
            dispatch({
                type: GET_CATALOGO,
                payload: response.data
            })
        })
    }
}

export function getDiscountCode(code){
    return function(dispatch){
        axios.get(`${tokens.server}discount/${code}`)
        .then((response)=>{
            dispatch({
                type: DISCOUNT_CODE,
                payload: response.data
            })
        })
    }
}